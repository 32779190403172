import React, {useState, useEffect} from "react";
import Page from "../../Components/Page";
import Table from "../../Components/Table";
import apiRequest from "../../helpers/connection";
import { useNavigate } from "react-router-dom";
import Popup from "../../Components/Popup";
import { FaEye, FaTrash, FaRegEdit } from "react-icons/fa";
import validator from "../../helpers/validator";

export default function Clients(){
    let navigate = useNavigate();

    let [refresh, setRefresh] = useState(false);
    let [clients, setClients] = useState([]);
    let [error, setError] = useState([]);
    let [newClient, setNewClient] = useState({});

    useEffect(() => {
        apiRequest(navigate,{
            method: "GET",
            route: "/floors",
            onReturn: (data)=>{
                let newFloors = data.map((floor) => 
                    [
                        floor.floorId,
                        floor.name,
                        <div>
                            <EditFloor floor={floor} refresh={() => {setRefresh(prev => !prev)}}/>
                            <Popup 
                                name={<FaTrash color="e84a50" />} 
                                whiteButton={true}
                                actionBtnLabel="Delete"
                                onActionBtnClick={() => {
                                    apiRequest(navigate,{
                                        method: "DELETE",
                                        route: "/floors/" + floor.floorId,
                                        okStatus: 204,
                                        onReturn: (data) => {
                                            setRefresh((prev) => !prev);
                                        }
                                    });
                                }}
                            >
                                <h2>Are You Sure?</h2>
                                <span>You are going to delete the user {floor.name}</span>
                            </Popup>

                        </div>
                    ]
                );
                setClients(newFloors);
            },
            onError: (response)=>{
                navigate("/error");
            }
        });
    } , [refresh]);
    
    return(
    <Page>
        <Table 
            header="View Floors"  
            headerRight = {
                <Popup 
                    name={<span className="plus-parent"><span className="big-plus">+</span> Add New Floor</span>}
                    actionBtnLabel = "Add New Floor"
                    onClose={
                        () => {
                            setError("");
                            setNewClient({});
                        }
                    }
                    onActionBtnClick = {function (){
                        let requiredFields = [
                            {key: "name", label: "Name"},
                        ];
                        let valError = validator.validate(requiredFields, newClient);
                        if(valError != ""){
                            setError(valError);
                            return false;
                        }
                        setError("");
                        apiRequest(navigate,{
                            method:"POST",
                            route: "/floors",
                            body:newClient,
                            okStatus: 201,
                            onReturn:(data)=>{
                                setRefresh( (prev) => !prev);
                                setNewClient({});
                            },
                            onError:()=>{
                                navigate("/error");
                            }
                        });
                        return true;
                    }}
                >
                    <h1>Add New Floor</h1>
                    <span className="error">{error}</span>
                    <label className="block login-label">Name</label>
                    <input className={"block shadow login-input "}  type="text" value={newClient.name ?? ""} onChange={(event) => {setNewClient((prev) => ({...prev, name:event.target.value}))}}/>
                </Popup>
            }
            headings = {["Floor ID","Floor Name",""]}
            data = {clients}
        />
    </Page>
    );
}

function EditFloor(props){
    let navigate = useNavigate();

    let [error, setError] = useState("");
    let [edit, setEdit] = useState({});

    return (
         <Popup 
             name={<FaRegEdit />}
             whiteButton={true}
             actionBtnLabel = "Edit Floor"
             onShow={
                 () =>{
                     setEdit({
                         name: props.floor.name,
                         floorId: props.floor.floorId
                     });
                 }
             }
             onClose={
                 () => {
                     setError("");
                     setEdit({});
                 }
             }
             onActionBtnClick = {function (){
                let requiredFields = [
                    {key: "name", label: "Name"},
                ];
                let valError = validator.validate(requiredFields, edit);
                if(valError != ""){
                    setError(valError);
                    return false;
                }
                setError("");
                apiRequest(navigate, {
                    route: "/floors/" + props.floor.floorId,
                    method: "PUT",
                    okStatus: 204,
                    body: edit,
                    onReturn: () => {
                        props.refresh();
                        return true;
                    }
                });
             }}
         >
             <h1>Edit Floor</h1>
             <span className="error">{error}</span>
             <label className="block login-label">Name</label>
             <input className={"block shadow login-input "}  type="text" value={edit.name ?? ""} onChange={(event) => {console.log(event.target.value); setEdit((prev) => ({...prev, name:event.target.value}))}} />
         </Popup>

    );
}