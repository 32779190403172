import React, { useState, useEffect } from "react";
import Page from "../../Components/Page";
import Table from "../../Components/Table";
import apiRequest from "../../helpers/connection";
import { useNavigate } from "react-router-dom";
import Popup from "../../Components/Popup";
import { FaEye, FaTrash, FaRegEdit } from "react-icons/fa";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import validator from "../../helpers/validator";

export default function Slots() {
    let navigate = useNavigate();

    let [refresh, setRefresh] = useState(false);
    let [slots, setSlots] = useState([]);
    let [floors, setFloors] = useState([]);
    let [sections, setSections] = useState([]);
    let [error, setError] = useState("");
    let [newSlot, setNewSlot] = useState({});

    useEffect(() => {
        // Fetch all floors
        apiRequest(navigate, {
            method: "GET",
            route: "/floors",
            onReturn: (data) => {
                let floorData = data.map((floor) => { return { label: floor.name, value: floor.floorId } });
                setFloors(floorData);
            }
        });

        // Fetch all slots
        apiRequest(navigate, {
            method: "GET",
            route: "/Slots",
            onReturn: (data) => {
                let newSlots = data.map((slot) =>
                    [
                        slot.slotId,
                        slot.name,
                        slot.section.name,
                        slot.section.floor.name,
                        <div>
                            <EditSlot slot={slot} refresh={() => { setRefresh(prev => !prev) }} />
                            <Popup
                                name={<FaTrash color="e84a50" />}
                                whiteButton={true}
                                actionBtnLabel="Delete"
                                onActionBtnClick={() => {
                                    apiRequest(navigate, {
                                        method: "DELETE",
                                        route: "/slots/" + slot.slotId,
                                        okStatus: 204,
                                        onReturn: (data) => {
                                            setRefresh((prev) => !prev);
                                        }
                                    });
                                }}
                            >
                                <h2>Are You Sure?</h2>
                                <span>You are going to delete the slot {slot.name}</span>
                            </Popup>
                        </div>
                    ]
                );
                setSlots(newSlots);
            },
            onError: (response) => {
                navigate("/error");
            }
        });
    }, [refresh]);

    function fetchSections(floorId) {
        setNewSlot((prev) => ({ ...prev, floorId: floorId }));
        // Fetch floor's sections
        apiRequest(navigate, {
            method: "GET",
            route: "/sections/" + floorId.value,
            onReturn: (data) => {
                let sectionData = data.map((section) => { return { label: section.name, value: section.sectionId } });
                setSections(sectionData);
                // Automatically select the section with the same name as the floor
                const matchingSection = sectionData.find(section => section.label === floorId.label);
                if (matchingSection) {
                    setNewSlot((prev) => ({ ...prev, sectionId: matchingSection }));
                }
            }
        });
    }

    return (
        <Page>
            <Table search={true}
                header="View Areas"
                headerRight={
                    <Popup
                        name={<span className="plus-parent"><span className="big-plus">+</span> Add New Area</span>}
                        actionBtnLabel="Add New Area"
                        onClose={() => {
                            setError("");
                            setNewSlot({});
                        }}
                        onActionBtnClick={function () {
                            let requiredFields = [
                                { key: "name", label: "Name" },
                                { key: "floorId", label: "Floor" },
                                { key: "sectionId", label: "Section" }
                            ];
                            let valError = validator.validate(requiredFields, newSlot);
                            if (valError !== "") {
                                setError(valError);
                                return false;
                            }
                            setError("");
                            apiRequest(navigate, {
                                method: "POST",
                                route: "/slots",
                                body: {
                                    ...newSlot,
                                    sectionId: newSlot.sectionId.value,
                                    floorId: newSlot.floorId.value
                                },
                                okStatus: 201,
                                onReturn: (data) => {
                                    setRefresh((prev) => !prev);
                                    setNewSlot({});
                                },
                                onError: () => {
                                    navigate("/error");
                                }
                            });
                        }}
                    >
                        <h1>Add New Area</h1>
                        <span className="error">{error}</span>
                        <label className="block login-label">Floor</label>
                        <Dropdown options={floors} value={newSlot.floorId} onChange={(value) => fetchSections(value)} />
                        <label className="block login-label">Section</label>
                        <Dropdown value={newSlot.sectionId} options={sections} disabled={true} />
                        <label className="block login-label">Area Name</label>
                        <input className={"block shadow login-input "} type="text" value={newSlot.name ?? ""} onChange={(event) => { setNewSlot((prev) => ({ ...prev, name: event.target.value })) }} />
                    </Popup>
                }
                headings={["Area ID", "Area Name", "Section Name", "Floor Name", ""]}
                data={slots}
            />
        </Page>
    );
}

function EditSlot(props) {
    let navigate = useNavigate();

    let [error, setError] = useState("");
    let [edit, setEdit] = useState({ slotId: props.slot.slotId });
    let [floors, setFloors] = useState([]);
    let [sections, setSections] = useState([]);

    function fetchSections(floor) {
        setEdit((prev) => ({ ...prev, floorId: floor }));
        setEdit((prev) => ({ ...prev, sectionId: null }));
        // Fetch floor's sections
        apiRequest(navigate, {
            method: "GET",
            route: "/sections/" + floor.value,
            onReturn: (data) => {
                let sectionData = data.map((section) => { return { label: section.name, value: section.sectionId } });
                setSections(sectionData);
                // Automatically select the section with the same name as the floor
                const matchingSection = sectionData.find(section => section.label === floor.label);
                if (matchingSection) {
                    setEdit((prev) => ({ ...prev, sectionId:matchingSection }));
                }
            }
        });
    }

    useEffect(() => {
        // Fetch all floors
        apiRequest(navigate, {
            method: "GET",
            route: "/floors",
            onReturn: (data) => {
                let floorData = data.map((floor) => { return { label: floor.name, value: floor.floorId } });
                setFloors(floorData);
            }
        });
    }, []);

    return (
        <Popup
            name={<FaRegEdit />}
            whiteButton={true}
            actionBtnLabel="Edit Area"
            onShow={() => {
                setEdit(prev => ({
                    ...prev,
                    name: props.slot.name,
                    floorId: {
                        value: props.slot.section.floor.floorId,
                        label: props.slot.section.floor.name
                    },
                    sectionId: {
                        value: props.slot.section.sectionId,
                        label: props.slot.section.name
                    }
                }));
            }}
            onClose={() => {
                setError("");
                setEdit({ slotId: props.slot.slotId });
            }}
            onActionBtnClick={function () {
                let requiredFields = [
                    { key: "name", label: "Name" },
                    { key: "floorId", label: "Floor" },
                    { key: "sectionId", label: "Section" }
                ];
                let valError = validator.validate(requiredFields, edit);
                if (valError !== "") {
                    setError(valError);
                    return false;
                }
                setError("");
                apiRequest(navigate, {
                    route: "/slots/" + props.slot.slotId,
                    method: "PUT",
                    okStatus: 204,
                    body: {
                        ...edit,
                        floorId: edit.floorId.value,
                        sectionId: edit.sectionId.value
                    },
                    onReturn: () => {
                        props.refresh();
                        return true;
                    }
                });
            }}
        >
            <h1>Edit Area</h1>
            <span className="error">{error}</span>
            <label className="block login-label">Floor</label>
            <Dropdown options={floors} value={edit.floorId} onChange={(value) => fetchSections(value)} />
            <label className="block login-label">Section</label>
            <Dropdown value={edit.sectionId} options={sections} disabled={true} />
            <label className="block login-label">Slot Name</label>
            <input className={"block shadow login-input "} type="text" value={edit.name ?? ""} onChange={(event) => { setEdit((prev) => ({ ...prev, name: event.target.value })) }} />
        </Popup>
    );
}
