
import React, {useState, useEffect} from "react";
import Page from "../../Components/Page";
import apiRequest from "../../helpers/connection";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import "./style.css";
import Table from "../../Components/Table";
import { FaEye } from "react-icons/fa";


export default function SingleClient(){
    let navigate = useNavigate();
    let [adv, setAdv] = useState(null);
    let params = useParams();

    console.log(adv);

    useEffect(() => {
        apiRequest(navigate,{
            method: "GET",
            route: "/Advertisments/" + params.adv_id,
            onReturn: (data) => {
                setAdv(data);
            }
        });
    }, []);

    return (
        <Page>
            <h1>Viewing Single Advertisment</h1>
            {adv == null ? <div className="center"><ClipLoader /></div> : <></>}
            {adv && <AdvertismentInfo adv={adv} />}
        </Page>
    )
}

function AdvertismentInfo(props){
  let navigate = useNavigate();
    return (
        <>
            <div className="container padded-container margined-container">
                <h2>Basic Info</h2>
                <div className="attribute">
                    <span className="attribute-name">Advertisment Name:</span> 
                    <span className="attribute-value">{props.adv.advertismentName}</span>
                </div>
                <div className="attribute">
                    <span className="attribute-name">Advertisment Type:</span> 
                    <span className="attribute-value">{props.adv.advType.name}</span>
                </div>
                <div className="attribute">
                    <span className="attribute-name">FLoor:</span> 
                    <span className="attribute-value">{props.adv.slot.section.floor.name}</span>
                </div>
                <div className="attribute">
                    <span className="attribute-name">Section:</span> 
                    <span className="attribute-value">{props.adv.slot.section.name}</span>
                </div>
                <div className="attribute">
                    <span className="attribute-name">Area:</span> 
                    <span className="attribute-value">{props.adv.slot.name}</span>
                </div>
            </div>
            <div className="margined-container">
                <Table 
                    header={props.adv.advertismentName + "'s Clients"}
                    headings={["Name", "Email", "Phone Number", "Company Name", "Address", ""]}
                    search={true}
                    data={props.adv.clients.map((client) => 
                        [
                          client.name,
                        client.email,
                        client.phoneNumber,
                        client.companyName,
                        client.address,
                        <div>
                            <button style={{padding: "10px"}} onClick={() => {navigate("/clients/" + client.clientId)}}>
                                <FaEye color="444ce7"/>
                            </button>
                        </div>
                        ]
                    )}
                />
            </div>
            
        </>

    );
}