import React, { useState } from 'react';
import './style.css';

const ChangePasswordComponent = (props) => {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    return (
        <div className='changePassword-page-container'>
        <div className="login-container">
            <img className="login-logo" src="./logo.png" alt="logo" />
            <h1 className="login-heading">{props.heading}</h1>
            <h2 className="login-subheading">{props.subheading}</h2>
            <h2 className="login-subheading error">{props.errorMsg}</h2>
            <div className="container padded-container login-form">
                <label className="block login-label">Old Password</label>
                <input 
                    className="block shadow login-input login-password-input" 
                    type="password" 
                    placeholder="********" 
                    onChange={(event) => setOldPassword(event.target.value)} 
                />
                <label className="block login-label">New Password</label>
                <input 
                    className="block shadow login-input login-password-input" 
                    type="password" 
                    placeholder="********" 
                    onChange={(event) => setNewPassword(event.target.value)} 
                />
                <label className="block login-label">Confirm New Password</label>
                <input 
                    className="block shadow login-input login-password-input" 
                    type="password" 
                    placeholder="********" 
                    onChange={(event) => setConfirmPassword(event.target.value)} 
                />
                <button 
                    className="shadow login-button" 
                    onClick={() => props.handleSubmit(oldPassword, newPassword, confirmPassword)}
                >
                    {props.button}
                </button>
            </div>
        </div>
        </div>
    );
};

export default ChangePasswordComponent;
