import LoginComponent from "../../Components/Login";
import ChangePassword from "../ChangePassword";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Route, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import apiRequest from "../../helpers/connection";
import helpers from "../../helpers/users";

export default function LoginPage(){
    let navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState("");

    return (
        
        <LoginComponent 
            heading="Login to your account"
            subheading="Welcome Back! Please enter your details"
            headings={["Username", "Password"]}
            types={["text", "password"]}
            errorMsg={errorMsg}
            button="Sign In"
            handleSubmit={(userName, password) =>{
                toast.loading("Logging in...", {duration: 1000});
                apiRequest(navigate,{
                    method: "POST",
                    route: "/auth/login",
                    body: {
                        userName: userName,
                        password: password
                    },
                    okStatus: 200,
                    onReturn: (data) => {
                        helpers.setUser(data.token);
                        navigate("/home")
                    },
                    onError: (response) => {
                        if(response.status == 400){
                            setErrorMsg("Incorrect Username or Password");
                            return;
                        }
                    }
                });
            }}
        />
        
    );
}