import React, {useState, useEffect} from "react";
import Page from "../../Components/Page";
import apiRequest from "../../helpers/connection";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import "./style.css";
import Table from "../../Components/Table";
import Popup from "../../Components/Popup";
import validator from "../../helpers/validator";
import { FaTrash, FaUndo } from "react-icons/fa";
import Dropdown from "react-dropdown";
import { dateToString } from "../../helpers/dates";
import { Button } from "@coreui/coreui";
import { Link } from "react-router-dom";
export default function SingleClient(){
    let navigate = useNavigate();

    let [client, setClient] = useState(null);
    let [refresh, setRefresh] = useState(false);
    let params = useParams();

    useEffect(() => {
        console.log("refreshed!");
        apiRequest(navigate,{
            method: "GET",
            route: "/clients/" + params.client_id,
            onReturn: (data) => {
                setClient(data);
            }
        });
    }, [refresh]);

    return (
        <Page>
            <h1>Viewing Single Client</h1>
            {client == null ? <div className="center"><ClipLoader /></div> : <></>}
            {client && <ClientInfo refresh={() => setRefresh((prev) => !prev)} client={client} />}
        </Page>
    )
}

function ClientInfo(props){
    let [selected, setSelected] = useState(0);

    return (
        <>
            <div className="container padded-container margined-container">
                <h2>Basic Info</h2>
                <div className="attribute">
                    <span className="attribute-name">Name:</span> 
                    <span className="attribute-value">{props.client.name}</span>
                </div>
                <div className="attribute">
                    <span className="attribute-name">Email:</span> 
                    <span className="attribute-value">{props.client.email}</span>
                </div>
                <div className="attribute">
                    <span className="attribute-name">Phone Number:</span> 
                    <span className="attribute-value">{props.client.phoneNumber}</span>
                </div>
                <div className="attribute">
                    <span className="attribute-name">Address:</span> 
                    <span className="attribute-value">{props.client.address}</span>
                </div>
                <div className="attribute">
                    <span className="attribute-name">Company Name:</span> 
                    <span className="attribute-value">{props.client.companyName}</span>
                </div>
            </div>
            <div className="margined-container padded-container container center">
                <div>
                    <button 
                        className={selected == 0 ? "styled-button" : "styled-button--white"} 
                        onClick={()=>{setSelected(0)}}
                    >
                        View Advs
                    </button>
                    <button 
                        className={selected == 1 ? "styled-button" : "styled-button--white"} 
                        onClick={()=>{setSelected(1)}}
                    >
                        View Payments
                    </button>
                    
                    {/* <button 
                        className={selected == 2 ? "styled-button" : "styled-button--white"} 
                        onClick={()=>{setSelected(2)}}
                    >
                        Bills & Payments
                    </button> */}
                </div>
            </div>
            {selected == 0 && <div className="margined-contaienr">
                <ViewClientAdvs client={props.client} refresh={props.refresh} />
            </div>}
            {selected == 1 && <div className="margined-contaienr">
                <ViewClientPayments client={props.client} refresh={props.refresh}/>
            </div>}
            {selected == 2 && <div className="margined-contaienr">
                <ViewBills client={props.client} refresh={props.refresh} />
            </div>}
        </>

    );
}



// function ViewTotalPayments(props) {
//     let navigate = useNavigate();

//     const today = new Date();
//     const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
//     const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

//     let [range, setRange] = useState({ startDate: dateToString(monthStart), endDate: dateToString(monthEnd) });
//     let [tableData, setTableData] = useState([]);
//     let [BillPayment, setBillPayment] = useState({ startDate: dateToString(monthStart), endDate: dateToString(monthEnd) });
//     let [selectedAdvertisementId, setSelectedAdvertisementId] = useState("");
//     let [advertisements, setAdvertisements] = useState([]);

//     const fetchAdvertisements = () => {
//         apiRequest(navigate, {
//             method: "GET",
//             route: "/Advertisments/client/activeAdvertisements/" + props.client.clientId,
//             onReturn: (data) => {
//                 let advData = data.map((adv) => ({ label: adv.advertismentName, value: adv.advertismentId }));
//                 setAdvertisements(advData);
//             },
//             onError: () => {
//                 navigate("/error");
//             }
//         });
//     };

//     const fetchTotalPayment = () => {
//         return new Promise((resolve, reject) => {
//             apiRequest(navigate, {
//                 method: "POST",
//                 body: BillPayment,
//                 route: "/Payments/total/adv/" + props.client.clientId,
//                 onReturn: (data) => {
//                     resolve(data);
//                 },
//                 onError: () => {
//                     navigate("/error");
//                     reject();
//                 }
//             });
//         });
//     };

//     const fetchTotalBill = () => {
//         return new Promise((resolve, reject) => {
//             apiRequest(navigate, {
//                 method: "POST",
//                 body: BillPayment,
//                 route: "/Clients/TotalAdvClientBill/" + props.client.clientId,
//                 onReturn: (data) => {
//                     resolve(data);
//                 },
//                 onError: () => {
//                     navigate("/error");
//                     reject();
//                 }
//             });
//         });
//     };

//     const handleButtonClick = () => {
//         Promise.all([fetchTotalPayment(), fetchTotalBill()])
//             .then(([payments, bills]) => {
//                 let combined = [
//                     {
//                         totalPayment: payments.totalPayment,  // Adjust this to match your API response structure
//                         total: bills.total                   // Adjust this to match your API response structure
//                     }
//                 ];
//                 setTableData(combined.map(item => [item.totalPayment, item.total]));
//             })
//             .catch(() => {
//                 // Handle errors if any of the requests fail
//                 navigate("/error");
//             });
//     };

//     return (
//         <Table
//             header={props.client.name + "'s Total Payments"}
//             search={false}
//             headerRight={
//                 <div className="range-control">
//                     <Dropdown
//                         className="react-dropdown-viewTotal"
//                         options={advertisements}
//                         value={selectedAdvertisementId}
//                         onFocus={fetchAdvertisements}
//                         onChange={(event) => { setBillPayment((prev) => ({ ...prev, advertismentId: event.value })) }}
//                         disabled={false}
//                     />
//                     <input className={"block shadow range-input"} type="date" value={BillPayment.startDate ?? ""} onChange={(event) => { setBillPayment((prev) => ({ ...prev, startDate: event.target.value })) }} />
//                     <input className={"block shadow range-input"} type="date" value={BillPayment.endDate ?? ""} onChange={(event) => { setBillPayment((prev) => ({ ...prev, endDate: event.target.value })) }} />

//                     <div className="center">
//                         <button onClick={handleButtonClick} className="styled-button"><FaUndo color="white" /></button>
//                     </div>
//                 </div>
//             }
//             headings={["Total Paid Amount", "Bill Amount"]}
//             data={tableData}
//         />
//     );
// }


function ViewBills(props){
    let navigate = useNavigate();

    // calculate the first day of the month and the last day of the month 
    const today = new Date();
    const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    let [range, setRange] = useState({startDate: dateToString(monthStart), endDate: dateToString(monthEnd)});
    let [refresh, setRefresh] = useState(false);
    let [tableData, setTableData] = useState([]);


    useEffect(() => {
        apiRequest(navigate, {
            route: "/clients/billables/" + props.client.clientId,
            method: "POST",
            body: range,
            onReturn: (data) => {
                // filter out payments that arent in the specified range
                let payments = props.client.payments.sort((a,b) => (a.paymentDate < b.paymentDate ? -1 : 1));
                payments = payments.filter((p) => p.paymentDate >= range.startDate);
                payments = payments.filter((p) => p.paymentDate <= range.endDate);

                // mix together the payment data and the bills data
                let i = 0;
                let j = 0;
                let newData = [];
                while(i < data.bills.length && j < payments.length){
                    if(data.bills[i].date < payments[j].paymentDate){
                        newData.push([data.bills[i].date, data.bills[i].amount.toLocaleString("en-US", {style: "currency", currency: "EGP"}), "-"]);
                        i++;
                    }else if(data.bills[i].date > payments[j].paymentDate){
                        newData.push([payments[j].paymentDate, "-", payments[j].amount.toLocaleString("en-US", {style: "currency", currency: "EGP"})]);
                        j++;
                    }else{
                        newData.push([payments[j].paymentDate, data.bills[i].amount.toLocaleString("en-US", {style: "currency", currency: "EGP"}), payments[j].amount.toLocaleString("en-US", {style: "currency", currency: "EGP"})]);
                        i++;
                        j++;
                    }
                }

                // if all of the payments are done put in the rest of the bills
                while (i < data.bills.length) {
                    newData.push([data.bills[i].date, data.bills[i].amount.toLocaleString("en-US", {style: "currency", currency: "EGP"}), "-"]);
                    i++;
                }

                // if all of the bills are done put in the rest of the payments
                while(j < payments.length){
                    console.log("here 3");
                    newData.push([payments[j].paymentDate, "-", payments[j].amount.toLocaleString("en-US", {style: "currency", currency: "EGP"})]);
                    j++;
                }
                setTableData(newData);
            }
        });
    }, [refresh]);

    return(
        <Table 
            header={props.client.name + "'s Bills & Payments"}
            search={true}
            headerRight={
                <div className="range-control">
                    <input className={"block shadow range-input"}  type="date" value={range.startDate ?? ""} onChange={(event) => {setRange((prev) => ({...prev, startDate:event.target.value}))}}/>
                    <input className={"block shadow range-input"}  type="date" value={range.endDate ?? ""} onChange={(event) => {setRange((prev) => ({...prev, endDate:event.target.value}))}}/>
                    <div className="center">
                        <button onClick={() => setRefresh(prev => !prev)}className="styled-button"><FaUndo color="white"/></button>
                    </div>
                </div>
            }
            headings={["Date", "Billed Amount", "Paid Amount"]}
            data={tableData}
        />
    );
}

function ViewClientPayments(props){
    let navigate = useNavigate();
    let [refresh, setRefresh] = useState(false);
    let [error, setError] = useState("");
    let [newPayment, setNewPayment] = useState({clientId: props.client.clientId,currency: "egp"});
    let [advertisements, setAdvertisements] = useState([]);
    let [allClientadvertisements, setallClientadvertisements] = useState([]);
    let [selectedAdvertisementId, setSelectedAdvertisementId] = useState("");
    let [selected, setSelected] = useState(0);
    
    useEffect(() => {
        apiRequest(navigate, {
          method: "GET",
          route: "/Advertisments/client/activeAdvertisements/" + props.client.clientId,
          onReturn: (data) => {
            setallClientadvertisements(data)
            let advData = data.map((adv) => ({ label: adv.advertismentName, value: adv.advertismentId }));
            setAdvertisements(advData);
          },
          onError: () => {
            //navigate("/error");
          }
        });
    }, [refresh]);
      
    
    return(
        <Table 
            header={props.client.name + "'s Payments"}
            search={true}
            headerRight={
                <Popup 
                    name={<span className="plus-parent"><span className="big-plus">+</span> Add Payment</span>}
                    actionBtnLabel = "Add Payment" 
                    onClose={
                        () => {
                            setError("");
                            setNewPayment({clientId: props.client.clientId,currency: "egp"});
                        }
                    }
                    onActionBtnClick = {function (){
                        let requiredFields = [
                            {key: "paymentDate", label: "Payment Date"},
                            {key: "amount", label: "Payment Amount"},
                        ];
                        let valError = validator.validate(requiredFields, newPayment);
                        if(valError != ""){
                            setError(valError);
                            return false;
                        }
                        setError("");
                        apiRequest(navigate,{
                            method:"POST",
                            route: "/payments",
                            body:newPayment,
                            okStatus: 201,
                            onReturn:(data)=>{
                                props.refresh();
                                setNewPayment({
                                    clientId: props.client.clientId,
                                    currency: "egp"
                                });
                            },
                            onError:()=>{
                                navigate("/error");
                            }
                        });
                        return true;
                    }}
                >
                    <h1>Add Payment</h1>
                    <span className="error">{error}</span>
                    {/* <label className="block login-label">Advertisment</label>
                    <Dropdown options={advertisements} value={advertisements.advertismentId}  onChange={(event) => setSelectedAdvertisementId(event.target.value)}
                    disabled={false} /> */}
                    <label className="block login-label">Advertisement</label>
                    <Dropdown
                        options={advertisements}
                        value={selectedAdvertisementId}
                       // onFocus={fetchAdvertisements} // Fetch advertisements when the dropdown is focused  
                        onChange={(event) => {setNewPayment((prev) => ({...prev, advertismentId:event.value}))}}
                        disabled={false}
                    />
                    <label className="block login-label">Payment Date</label>
                    <input className={"block shadow login-input "}  type="date" value={newPayment.paymentDate ?? ""} onChange={(event) => {setNewPayment((prev) => ({...prev, paymentDate:event.target.value}))}}/>
                    <label className="block login-label">Payment Amount</label>
                    <input className={"block shadow login-input "}  type="number" value={newPayment.amount ?? ""} onChange={(event) => {setNewPayment((prev) => ({...prev, amount:event.target.value}))}}/>
                </Popup>
            }
            headings={[ "Adv Name", "Paid","Billed" , ""]}
            data={allClientadvertisements.map((advpayment) => 
                [
                    advpayment.advertismentName,
                    advpayment.totalPayment,
                    advpayment.totalBill,
                    //advpayment.advertismentId
                    //props.client.clientId
                    // <div>
                    //     <Popup 
                    //         name={<FaTrash color="e84a50" />} 
                    //         whiteButton={true}
                    //         actionBtnLabel="Delete"
                    //         onActionBtnClick={() => {
                    //             apiRequest(navigate,{
                    //                 method: "DELETE",
                    //                 route: "/payments/" + payment.paymentId,
                    //                 okStatus: 204,
                    //                 onReturn: (data) => {
                    //                     props.refresh();
                    //                 }
                    //             });
                    //         }}
                    //     >
                    //         <h2>Are You Sure?</h2>
                    //         <span>You are going to delete the Payment from date {payment.paymentDate}</span>
                    //     </Popup>
                    // </div>
                    <div>
                        {/* <Link className="sidebar-nav-link" to="/PaymnetDetails" >payment details</Link> */}
                        <Link 
    className="sidebar-nav-link paymentDetails" 
    to={`/PaymentDetails/${props.client.clientId}/${advpayment.advertismentId}`}
>
    payment details
</Link>
                    </div>
                    
                ] 
            )}

        />
    );

}



function ViewClientAdvs(props){
    let navigate = useNavigate();

    let [error, setError] = useState("");
    let [newAdv, setNewAdv] = useState({clientId: props.client.clientId});
    let [floors, setFloors] = useState([]);
    let [section, setSections] = useState([]);
    let [slot, setSlots] = useState({});
    let [advs, setAdvs] = useState({});
    let [matchsec , setmatchsec] = useState({})

    function fetchSections(floorId, floorName) {
        setNewAdv((prev) => ({ ...prev, advertismentId: null }));
        setSlots([]);
        setAdvs([]);

        // Fetch floor's sections
        apiRequest(navigate, {
            method: "GET",
            route: "/sections/" + floorId,
            onReturn: (data) => {
                let sectionData = data.map((section) => ({ label: section.name, value: section.sectionId }));
                setSections(sectionData);
                
                // Automatically select the section with the same name as the floor
                const matchingSection = sectionData.find(section => section.label === floorName);
                if (matchingSection) {
                    setmatchsec((prev) => ({ ...prev, sectionId: matchingSection }));
                    fetchSlots(matchingSection.value);
                }
            }
        });
    }
    function fetchSlots(sectionId){
        setNewAdv((prev) => ({...prev, advertismentId: null}));
        setAdvs([]);
        // fetch sections's slots
        apiRequest(navigate,{
            method: "GET",
            route: "/slots/" + sectionId,
            
            onReturn: (data) => {
                
                let slotData = data.map((slot) => {return {label: slot.name, value: slot.slotId}});
                setSlots(slotData);
            }
        });
    }

    function fetchAdvs(slotId){
        // fetch sections's slots
        apiRequest(navigate,{
            method: "GET",
            route: "/advertisments/slot/" + slotId,
            
            onReturn: (data) => {
                let advData = data.map((adv) => {return {label: adv.advertismentName, value: adv.advertismentId}});
                setAdvs(advData);
            }
        });
    }

    useEffect(() => {
        // fetch all floors
        apiRequest(navigate,{
            method: "GET",
            route: "/floors",
            onReturn: (data) => {
                let floorData = data.map((floor) => {return {label: floor.name, value: floor.floorId}});
                setFloors(floorData);
            }
        });
    }, []);

    let periodOptions = [
        {label: "Monthly", value: "Monthly"},
        {label: "Quarterly", value: "Quarterly"},
        {label: "Half Yearly", value: "HalfYearly"},
        {label: "Yearly", value: "Yearly"},
    ];

    return(
        <Table 
            header={props.client.name + "'s Advs"}
            headerRight={
                <Popup 
                    name={<span className="plus-parent"><span className="big-plus">+</span> Link Adv</span>}
                    actionBtnLabel = "Link Adv" 
                    onClose={
                        () => {
                            setError("");
                            setNewAdv({clientId: props.client.clientId});
                            setSections([]);
                            setSlots([]);
                            setAdvs([]);
                        }
                    }
                    onActionBtnClick = {function (){
                        let requiredFields = [
                            {key: "startDate", label: "Start Date"},
                            {key: "expirationDate", label: "Expiration Date"},
                            {key: "paymentAmount", label: "Payment Amount"},
                            {key: "paymentPeriod", label: "Payment Period"},
                            {key: "advertismentId", label: "Advertisment"},
                        ];
                        let valError = validator.validate(requiredFields, newAdv);
                        if(valError != ""){
                            setError(valError);
                            return false;
                        }
                        setError("");
                        apiRequest(navigate,{
                            method:"POST",
                            route: "/advertisments/linkclient",
                            body:newAdv,
                            okStatus: 200,
                            onReturn:(data)=>{
                                props.refresh();
                                setNewAdv({clientId: props.client.clientId});
                            },
                            onError:()=>{
                                navigate("/error");
                            }
                        });
                        return true;
                    }}
                >
                    <h1>Link Adv</h1>
                    <span className="error">{error}</span>
                    <label className="block login-label">Floor</label>
                    <Dropdown 
                        options={floors} 
                        onChange={(value) => fetchSections(value.value, value.label)} 
                    />
                    <label className="block login-label">Section</label>
                    <Dropdown 
                        value={matchsec.sectionId} 
                        onChange={(value) => fetch(value.value, value.label)} 
                        options={section} 
                        disabled={true} 
                        
                    />
                    
                    <label className="block login-label">Area</label>
                    <Dropdown  options={slot} onChange={(value) => fetchAdvs(value.value)} />
                    <label className="block login-label">Advertisment</label>
                    <Dropdown  options={advs} onChange={(value) => {setNewAdv((prev) => ({...prev, advertismentId:value.value}))}} />
                    <label className="block login-label">Start Date</label>
                    <input className={"block shadow login-input "}  type="date" value={newAdv.startDate ?? ""} onChange={(event) => {setNewAdv((prev) => ({...prev, startDate:event.target.value}))}}/>
                    <label className="block login-label">Expiration Date</label>
                    <input className={"block shadow login-input "}  type="date" value={newAdv.expirationDate ?? ""} onChange={(event) => {setNewAdv((prev) => ({...prev, expirationDate:event.target.value}))}}/>
                    <label className="block login-label">Payment Period</label>
                    <Dropdown  options={periodOptions} onChange={(value) => {setNewAdv((prev) => ({...prev, paymentPeriod:value.value}))}} />
                    <label className="block login-label">Payment Amount</label>
                    <input className={"block shadow login-input "}  type="number" value={newAdv.paymentAmount ?? ""} onChange={(event) => {setNewAdv((prev) => ({...prev, paymentAmount:event.target.value}))}}/>
                </Popup>
            }
            search={true}
            headings={["Floor", "Section", "Area", "Advertisment Name", "Start Date", "Expired Date", "Payment Period", "Payment Amount"]}
            data={props.client.clientAdvs.map((ad) => 
                [
                    ad.advertisment.slot.section.floor.name,
                    ad.advertisment.slot.section.name,
                    ad.advertisment.slot.name,
                    ad.advertisment.advertismentName,
                    ad.startDate,
                    ad.expirationDate,
                    ad.paymentPeriod,
                    ad.paymentAmount.toLocaleString('en-US', {style:'currency', currency: "EGP"}),
                ]
            )}
        />

    );
}
