import Cookies from "js-cookie";

function setUser(token){
    Cookies.set("ads_tkn", token);
}

function getJwtData(){
    try {
      return JSON.parse(atob(Cookies.get("ads_tkn").split('.')[1]));
    } catch (e) {
      return null;
    }
};

function getUserName(){
  if(getJwtData() == null) return null;
  return getJwtData().username;
}

const helpers = {
  setUser: setUser,
  getUserName: getUserName
};

export default helpers;