import React, {useState, useEffect} from "react";
import Page from "../../Components/Page";
import Table from "../../Components/Table";
import apiRequest from "../../helpers/connection";
import { useNavigate } from "react-router-dom";
import Popup from "../../Components/Popup";
import { FaEye, FaTrash, FaRegEdit } from "react-icons/fa";
import validator from "../../helpers/validator";

export default function Clients(){
    let navigate = useNavigate();

    let [refresh, setRefresh] = useState(false);
    let [clients, setClients] = useState([]);
    let [error, setError] = useState("");
    let [newClient, setNewClient] = useState({});

    useEffect(() => {
        apiRequest(navigate, {
            method: "GET",
            route: "/clients",
            onReturn: (data)=>{
                let newClients = data.map((client) => 
                    [
                        client.name,
                        client.email,
                        client.phoneNumber,
                        client.companyName,
                        client.address,
                        <div>
                            <EditClient client={client} refresh={() => setRefresh(prev => !prev)}/>
                            <button style={{padding: "10px"}} onClick={() => {navigate("/clients/" + client.clientId)}}>
                                <FaEye color="444ce7"/>
                            </button>
                            <Popup 
                                name={<FaTrash color="e84a50" />} 
                                whiteButton={true}
                                actionBtnLabel="Delete"
                                onActionBtnClick={() => {
                                    apiRequest(navigate,{
                                        method: "DELETE",
                                        route: "/clients/" + client.clientId,
                                        okStatus: 204,
                                        onReturn: (data) => {
                                            setRefresh((prev) => !prev);
                                        }
                                    });
                                }}
                            >
                                <h2>Are You Sure?</h2>
                                <span>You are going to delete the user {client.name}</span>
                            </Popup>
                        </div>
                    ]
                );
                setClients(newClients);
            },
            onError: (response)=>{
                navigate("/error");
            }
        });
    } , [refresh]);
    
    return(
    <Page>
        <Table search={true}
            header="View Clients"
            headerRight = {
                <Popup 
                    name={<span className="plus-parent"><span className="big-plus">+</span> Create Client</span>}
                    actionBtnLabel = "Create Client"
                    onClose={
                        () => {
                            setError("");
                            setNewClient({});
                        }
                    }
                    onActionBtnClick = {function (){
                        let requiredFields = [
                            {key: "name", label: "Name"},
                            {key: "email", label: "Email"},
                            {key: "phoneNumber", label: "Phone Number"},
                            {key: "address", label: "Address"},
                            {key: "companyName", label: "Company Name"},
                        ];
                        let valError = validator.validate(requiredFields, newClient);
                        if(valError != ""){
                            setError(valError);
                            return false;
                        }
                        // const phoneRegex = new RegExp("^01[0-2,5]{1}[0-9]{8}$");
                        // console.log(newClient.phoneNumber);
                        // console.log(phoneRegex.test(newClient.phoneNumber));
                        // if(!phoneRegex.test(newClient.phoneNumber)){
                        //     setError("Invalid Phone Number!");
                        //     return false;
                        // }
                        setError("");
                        apiRequest(navigate,{
                            method:"POST",
                            route: "/clients",
                            body:newClient,
                            okStatus: 201,
                            onReturn:(data)=>{
                                setRefresh( (prev) => !prev);
                                setNewClient({});
                            },
                            onError:()=>{
                                navigate("/error");
                            }
                        });
                        return true;
                    }}
                >
                    <h1>Create New Client</h1>
                    <span className="error">{error}</span>
                    <label className="block login-label">Name</label>
                    <input className={"block shadow login-input "}  type="text" value={newClient.name ?? ""} onChange={(event) => {setNewClient((prev) => ({...prev, name:event.target.value}))}}/>
                    <label className="block login-label">Email</label>
                    <input className={"block shadow login-input "}  type="text" value={newClient.email ?? ""} onChange={(event) => {setNewClient((prev) => ({...prev, email:event.target.value}))}}/>
                    <label className="block login-label">Phone Number</label>
                    <input className={"block shadow login-input "}  type="text" value={newClient.phoneNumber ?? ""} onChange={(event) => {setNewClient((prev) => ({...prev, phoneNumber:event.target.value}))}}/>
                    <label className="block login-label">Address</label>
                    <input className={"block shadow login-input "}  type="text" value={newClient.address ?? ""} onChange={(event) => {setNewClient((prev) => ({...prev, address:event.target.value}))}}/>
                    <label className="block login-label">Company Name</label>
                    <input className={"block shadow login-input "}  type="text" value={newClient.companyName ?? ""} onChange={(event) => {setNewClient((prev) => ({...prev, companyName:event.target.value}))}}/>
                </Popup>
            }
            headings = {["Name", "Email", "Phone Number", "Company Name", "Address", ""]}
            data = {clients}
        
        />
    </Page>
    );
}

function EditClient(props){
    let navigate = useNavigate();

    let [error, setError] = useState("");
    let [edit, setEdit] = useState({});

    return (
         <Popup 
             name={<FaRegEdit />}
             whiteButton={true}
             actionBtnLabel = "Edit Client"
             onShow={
                 () =>{
                     setEdit({
                         clientId: props.client.clientId,
                         name: props.client.name,
                         email: props.client.email,
                         phoneNumber: props.client.phoneNumber,
                         address: props.client.address,
                         companyName: props.client.companyName,
                     });
                 }
             }
             onClose={
                 () => {
                     setError("");
                     setEdit({});
                 }
             }
             onActionBtnClick = {function (){
                let requiredFields = [
                    {key: "name", label: "Name"},
                    {key: "email", label: "Email"},
                    {key: "phoneNumber", label: "Phone Number"},
                    {key: "address", label: "Address"},
                    {key: "companyName", label: "Company Name"},
                ];
                let valError = validator.validate(requiredFields, edit);
                if(valError != ""){
                    setError(valError);
                    return false;
                }
                const phoneRegex = new RegExp("^01[0-2,5]{1}[0-9]{8}$");
                if(!phoneRegex.test(edit.phoneNumber)){
                    setError("Invalid Phone Number!");
                    return false;
                }
                setError("");
                apiRequest(navigate, {
                    route: "/clients/" + props.client.clientId,
                    method: "PUT",
                    okStatus: 204,
                    body: edit,
                    onReturn: () => {
                        props.refresh();
                        return true;
                    }
                });
             }}
         >
            <h1>Edit This Client</h1>
            <span className="error">{error}</span>
            <label className="block login-label">Name</label>
            <input className={"block shadow login-input "}  type="text" value={edit.name ?? ""} onChange={(event) => {setEdit((prev) => ({...prev, name:event.target.value}))}}/>
            <label className="block login-label">Email</label>
            <input className={"block shadow login-input "}  type="text" value={edit.email ?? ""} onChange={(event) => {setEdit((prev) => ({...prev, email:event.target.value}))}}/>
            <label className="block login-label">Phone Number</label>
            <input className={"block shadow login-input "}  type="text" value={edit.phoneNumber ?? ""} onChange={(event) => {setEdit((prev) => ({...prev, phoneNumber:event.target.value}))}}/>
            <label className="block login-label">Address</label>
            <input className={"block shadow login-input "}  type="text" value={edit.address ?? ""} onChange={(event) => {setEdit((prev) => ({...prev, address:event.target.value}))}}/>
            <label className="block login-label">Company Name</label>
            <input className={"block shadow login-input "}  type="text" value={edit.companyName ?? ""} onChange={(event) => {setEdit((prev) => ({...prev, companyName:event.target.value}))}}/>
         </Popup>

    );
}