let dayToString = function (day){
    return day > 9 ? ""+day : "0"+day;
}

let dateToString = function dateToString(date){
    return date.getFullYear() + "-" + dayToString(date.getMonth() + 1) + "-" +  dayToString(date.getDate());
}

let addWeeks = function (date, weeks){
    date.setDate(date.getDate() + weeks * 7);
    return date;
}

let addDays = function (date, days){
    date.setDate(date.getDate() + days);
    return date;
}

export {dayToString, dateToString, addWeeks, addDays};