import React, {useState, useEffect} from "react";
import Page from "../../Components/Page";
import apiRequest from "../../helpers/connection";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import "./style.css";
import Table from "../../Components/Table";
import Popup from "../../Components/Popup";
import validator from "../../helpers/validator";
import Dropdown from "react-dropdown";
import { dateToString } from "../../helpers/dates";
import { Button } from "@coreui/coreui";
import { Link } from "react-router-dom";
import { FaEye, FaTrash, FaRegEdit } from "react-icons/fa";


export default function PaymnetDetails(){
    let navigate = useNavigate();
    let { clientId, advertismentId } = useParams();
    let [refresh, setRefresh] = useState(false);
    let [payments, setPayments] = useState([]);
    let [error, setError] = useState("");
    let [newClient, setNewClient] = useState({});
    // /Clients/client/1/advertisement/5010/payments
    useEffect(() => {
        apiRequest(navigate, {
            method: "GET",
            route: "/Clients/client/" + clientId + "/advertisement/" + advertismentId + "/payments",
            onReturn: (data)=>{
                let newPayments = data.map((payment) => 
                    [
                        payment.paymentDate,
                        payment.amount
                        // <div>
                        //    {/*  <EditClient client={client} refresh={() => setRefresh(prev => !prev)}/> */}
                        //     <Popup 
                        //         name={<FaTrash color="e84a50" />} 
                        //         whiteButton={true}
                        //         actionBtnLabel="Delete"
                        //         onActionBtnClick={() => {
                        //             apiRequest(navigate,{
                        //                 method: "DELETE",
                        //                 route: "/clients/" + client.clientId,
                        //                 okStatus: 204,
                        //                 onReturn: (data) => {
                        //                     setRefresh((prev) => !prev);
                        //                 }
                        //             });
                        //         }}
                        //     >
                        //         <h2>Are You Sure?</h2>
                        //         <span>You are going to delete the user {client.name}</span>
                        //     </Popup>
                        // </div>
                    ]
                );
                setPayments(newPayments);
            },
            onError: (response)=>{
                navigate("/error");
            }
        });
    } , [refresh]);

return(
<Page>
    <Table search={false}
        header="View Payment Details"
       // headerRight = {
        //     <Popup 
        //         name={<span className="plus-parent"><span className="big-plus">+</span> Create Client</span>}
        //         actionBtnLabel = "Create Client"
        //         onClose={
        //             () => {
        //                 setError("");
        //                 setNewClient({});
        //             }
        //         }
        //         onActionBtnClick = {function (){
        //             let requiredFields = [
        //                 {key: "name", label: "Name"},
        //                 {key: "email", label: "Email"},
        //                 {key: "phoneNumber", label: "Phone Number"},
        //                 {key: "address", label: "Address"},
        //                 {key: "companyName", label: "Company Name"},
        //             ];
        //             let valError = validator.validate(requiredFields, newClient);
        //             if(valError != ""){
        //                 setError(valError);
        //                 return false;
        //             }
        //             // const phoneRegex = new RegExp("^01[0-2,5]{1}[0-9]{8}$");
        //             // console.log(newClient.phoneNumber);
        //             // console.log(phoneRegex.test(newClient.phoneNumber));
        //             // if(!phoneRegex.test(newClient.phoneNumber)){
        //             //     setError("Invalid Phone Number!");
        //             //     return false;
        //             // }
        //             setError("");
        //             apiRequest(navigate,{
        //                 method:"POST",
        //                 route: "/clients",
        //                 body:newClient,
        //                 okStatus: 201,
        //                 onReturn:(data)=>{
        //                     setRefresh( (prev) => !prev);
        //                     setNewClient({});
        //                 },
        //                 onError:()=>{
        //                     navigate("/error");
        //                 }
        //             });
        //             return true;
        //         }}
        //     >
        //         <h1>Create New Client</h1>
        //         <span className="error">{error}</span>
        //         <label className="block login-label">Name</label>
        //         <input className={"block shadow login-input "}  type="text" value={newClient.name ?? ""} onChange={(event) => {setNewClient((prev) => ({...prev, name:event.target.value}))}}/>
        //         <label className="block login-label">Email</label>
        //         <input className={"block shadow login-input "}  type="text" value={newClient.email ?? ""} onChange={(event) => {setNewClient((prev) => ({...prev, email:event.target.value}))}}/>
        //         <label className="block login-label">Phone Number</label>
        //         <input className={"block shadow login-input "}  type="text" value={newClient.phoneNumber ?? ""} onChange={(event) => {setNewClient((prev) => ({...prev, phoneNumber:event.target.value}))}}/>
        //         <label className="block login-label">Address</label>
        //         <input className={"block shadow login-input "}  type="text" value={newClient.address ?? ""} onChange={(event) => {setNewClient((prev) => ({...prev, address:event.target.value}))}}/>
        //         <label className="block login-label">Company Name</label>
        //         <input className={"block shadow login-input "}  type="text" value={newClient.companyName ?? ""} onChange={(event) => {setNewClient((prev) => ({...prev, companyName:event.target.value}))}}/>
        //     </Popup>
        //}
        headings = {["payment Date", "Payment Amount",]}
        data = {payments}
    
    />
</Page>
);
}


