import Cookies from "js-cookie";
// https://localhost:7196/
//https://adsapi.albostan-mall.com/
export default function apiRequest(navigate, props){
    props.okStatus = props.okStatus ?? 200;
    fetch(props.overrideRoute ? props.route : ("https://adsapi.albostan-mall.com/api" + props.route), {
        method:props.method ?? "GET",
        headers: new Headers(
            {
                'content-type': 'application/json',
                'Authorization': "Bearer " + Cookies.get("ads_tkn")
            }
            ),
        body: props.body ? JSON.stringify(props.body) : null,
    }).then((response) => {
        if(response.status == props.okStatus && response.status != 204){
            response.json().then(data => {
                props.onReturn(data);
            });
        }else if(response.status == props.okStatus){
            props.onReturn();
        }else if(response.status == 401){
            Cookies.remove("ads_tkn");
            // navigate to login
            navigate("/");
        }else{
            if(props.onError != null){
                props.onError(response);
            }else{
                navigate("/error");
            }
        }
    }); 

}