import './style.css';
import {useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import toolkitImage from "./icons/toolkit.svg";
import logoutImg from "./icons/logout.svg";
import Cookies from "js-cookie";
import helpers from '../../helpers/users';
import { FaUser, FaAd, FaBuilding, FaAngleDown, FaAngleRight, FaHome, FaLock, FaCity, FaLocationArrow} from 'react-icons/fa';
import {
    CDBSidebar,
    CDBSidebarHeader,
    CDBSidebarMenuItem,
    CDBSidebarContent,
    CDBSidebarMenu,
    CDBSidebarSubMenu,
    CDBSidebarFooter,
    CDBBadge,
    CDBContainer,
  } from 'cdbreact';
  
  

export default function SideBar(){
    let navigate = useNavigate();
    return(
        <div className="sidebar">
           {/*  <div className="logo-container">
                <img className="sidebar-logo" src="./logo.png" alt="logo" />
            </div> */}
            <nav className="sidebar-nav">
                {/* <div className="sidebar-nav-link-container">
                    <span style={{marginRight: "1ch"}}>
                        <FaHome />
                    </span>
                    <Link className="sidebar-nav-link" to="/home" >Dashboard</Link>
                </div> */}
                {/* <div className="sidebar-nav-link-container">
                    <span style={{marginRight: "1ch"}}>
                        <FaUser />
                    </span>
                    <Link className="sidebar-nav-link" to="/clients" >Manage Clients</Link>
                </div> */}
                {/* <div className="sidebar-nav-link-container">
                    <span style={{marginRight: "1ch"}}>
                        <FaAd />
                    </span>
                    <Link className="sidebar-nav-link" to="/advertisments" >Manage Advs</Link>
                </div> */}
                {/* <div className="sidebar-nav-link-container">
                    <span style={{marginRight: "1ch"}}>
                        <FaLock />
                    </span>
                    <Link className="sidebar-nav-link" to="/changePassword" >Change Your Password</Link>
                </div> */}
                {/* <div className="sidebar-nav-link-container">
                    <span style={{marginRight: "1ch"}}>
                        <FaCity />
                    </span>
                    <Link className="sidebar-nav-link" to="/floors" >Manage Floors</Link>
                </div> */}
                {/* <div className="sidebar-nav-link-container">
                    <span style={{marginRight: "1ch"}}>
                        <FaBuilding />
                    </span>
                    <Link className="sidebar-nav-link" to="/sections">Manage Sections</Link>
                </div> */}
                {/* <div className="sidebar-nav-link-container">
                    <span style={{marginRight: "1ch"}}>
                        <FaLocationArrow />
                    </span>
                    <Link className="sidebar-nav-link" to="/slots">Manage Areas</Link>
                </div> */}
            </nav>
            {/* <div className="sidebar-bottom">
                <div className="sidebar-bottom-container">
                    <div>
                        <div className="sidebar-bottom-light">Logged in as</div>
                        <div className="sidebar-bottom-dark">{helpers.getUserName()}</div>
                    </div>
                    <button 
                        onClick={function(){
                            Cookies.remove("ads_tkn", {path:"/firstlogin"});
                            Cookies.remove("ads_tkn", {path:"/"});
                            navigate("/");
                        }}
                    >
                        <img src={logoutImg} style={{width:"1.2rem"}} /></button>
                </div>
            </div> */}
            <CDBSidebar textColor="#333" backgroundColor="#f0f0f0">
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
          <div className="container" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="./logo.png"
              style={{ width: '60px' }}
            /> 
          </div>
        </CDBSidebarHeader>
        <CDBSidebarContent>
          <CDBSidebarMenu>
            {/* < CDBSidebarMenuItem icon = "<CDBSidebarMenuItem icon="
            fa - lock "><Link className"
            sidebar - nav - link > < Link className = "sidebar-nav-link"
            to = "/clients" > Manage Clients < /Link></CDBSidebarMenuItem > */}
                            <CDBSidebarMenuItem icon="chart-line" iconType="solid">
            <Link className="sidebar-nav-link" to="/Home">Dashboard</Link>
            </CDBSidebarMenuItem>
                <CDBSidebarMenuItem icon="chart-line" iconType="solid">
            <Link className="sidebar-nav-link" to="/clients" >Manage Clients</Link>
            </CDBSidebarMenuItem>

            <CDBSidebarMenuItem icon="chart-line" iconType="solid">
            <Link className="sidebar-nav-link" to="/advertisments" >Manage Ads</Link>
            </CDBSidebarMenuItem>
                <hr></hr>
            <CDBSidebarMenuItem icon="chart-line" iconType="solid">
            <Link className="sidebar-nav-link" to="/floors" >Manage Floors</Link>
            </CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="chart-line" iconType="solid">
            <Link className="sidebar-nav-link" to="/sections">Manage Sections</Link>
            </CDBSidebarMenuItem>
            <CDBSidebarMenuItem icon="chart-line" iconType="solid">
            <Link className="sidebar-nav-link" to="/slots">Manage Areas</Link>
            </CDBSidebarMenuItem>
            <hr></hr>
            <CDBSidebarMenuItem icon="chart-line" iconType="solid">
            <Link className="sidebar-nav-link" to="/changePassword" >Change Your Password</Link>
            </CDBSidebarMenuItem>
          </CDBSidebarMenu>
        </CDBSidebarContent>
        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div>
                        <div className="sidebar-bottom-light">Logged in</div>
                        <div className="sidebar-bottom-dark">{helpers.getUserName()}</div>
                    </div>
                    <button 
                        onClick={function(){
                            Cookies.remove("ads_tkn", {path:"/firstlogin"});
                            Cookies.remove("ads_tkn", {path:"/"});
                            navigate("/");
                        }}
                    >
                        <img src={logoutImg} style={{width:"1.2rem"}} /></button>
        </CDBSidebarFooter>

      </CDBSidebar>
        </div>
    );
}

function SideDropDown(props){
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);

    const toggleOpen = () => {
        if (isOpen) {
            contentRef.current.height = 0;
            contentRef.current.maxHeight = 0;
        } else {
            contentRef.current.height = contentRef.current.scrollHeight;
            contentRef.current.maxHeight = contentRef.current.scrollHeight;
        }
        setIsOpen((prev) => !prev);
    };

    return (
        <div className="dropdown">
            <div className="dropdown-title" onClick={toggleOpen}>
                <span style={{marginRight: "1ch"}}>
                    {props.titleIcon}
                </span>
                <span style={{marginRight: "1ch"}}>
                    {props.title}
                </span>
                <span style={{position: "relative", top: "3px"}}>
                    {isOpen ? <FaAngleDown size="20px" /> : <FaAngleRight size="20px"/>}
                </span>
            </div>
            <div
                ref={contentRef}
                className="dropdown-content"
                style={{
                    transition: 'max-height 0.3s ease-out',
                }}
            >
                {isOpen && props.children}
            </div>
        </div>
    );
}