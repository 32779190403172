// this is a function that validates an object obj
// if the array in fields is null or an empty string 
// a validation error will return otherwise nothing happens 
// in: fields -> array
// in: obj -> object
function validate(fields, obj){
    for(let i = 0; i< fields.length; i++){
        if(!(fields[i].key in obj) || obj[fields[i].key] == null || obj[fields[i].key] == ""){
            return "Missing required field " + fields[i].label;
        }
    }
    return "";
}

let validator = {
    validate,
};

export default validator;