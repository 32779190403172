import classes from "./TableRow.module.css";

function TableRow(props) {
  return (
    <tr>
      {props.rowData.map((row, index) => {
        return <td key={index}>{row}</td>
      })}
    </tr>
  );
}

export default TableRow;
