import "./style.css";
import {useState, useEffect} from "react";

/* Usage Example:
    <Popup name="add Daypart">
      <>
        <h1>Test</h1>
        <button className='styled-button'>Do something</button>
      </>
    </Popup>
*/

export default function Popup(props){
    const [popupShown, setPopupShown] = useState("hidden");

    return(
        <>
            <button className={ props.whiteButton ? "" : "styled-button" } onClick={() => {if(props.onShow != null){props.onShow();} setPopupShown("shown")}}>{props.name}</button>
            <div className={"popup " + popupShown}>
                <div className={"popup-container container"}>
                    <button className={"popup-close-btn"} onClick={() => {
                        if(props.onClose != null) props.onClose();
                        setPopupShown("hidden");
                    }}>x</button>
                    {props.children}
                    <div className="popup-form-buttons">
                        <button 
                            className="styled-button--white"
                            onClick={function(){
                                if(props.onClose != null) props.onClose();
                                setPopupShown("hidden");
                            }}
                        >
                            Cancel
                        </button>
                        <button 
                            className="styled-button"
                            onClick={function(){
                                let close = props.onActionBtnClick();
                                if(close !== false){
                                    setPopupShown("hidden");
                                }
                            }}
                        >
                            {props.actionBtnLabel}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}