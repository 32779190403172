import "./style.css";
import SideBar from "../SideBar";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import helpers from "../../helpers/users";

export default function Page(props){
    let navigate = useNavigate();

    useEffect(()=>{
        if(helpers.getUserName() == null){
            navigate("/", {replace:true});
        }
    },[]);

    return (
        <div className="page">
            <SideBar></SideBar>
            <main>
                {props.children}
            </main>
        </div>
    );
}