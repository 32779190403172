import TableRow from "./TableRow";
import classes from "./Table.module.css";
import {useEffect, useState} from "react";

function Table(props) {
  const [hidden, setHidden] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [data, setData] = useState([]);

  function searchInList(keyString){
      let newList = [];
      if(keyString == "") return newList;
      for(let row of props.data){
        for(let element of row){
          if(element.toString().toLowerCase().search(keyString.toLowerCase()) != -1){
              newList.push(row);
              break;
          }
        }
      }
      return newList;
  }

  function getData(){
    if(isSearch){
      return data;
    }else{
      return props.data;
    }
  }

  return (
    <div style={{overflowX: "auto"}} className={classes.card}>
      <div className={classes.header}>
        <span className={classes.headerHeading}>{props.header}</span>
        <div>
          {props.search ? <input
            className={classes.search}
            type="text"
            name="search"
            placeholder="Search"
            onChange={function (event){
              setData(searchInList(event.target.value));
              setIsSearch(event.target.value != "");
            }}
          /> : <></>}
          {props.headerRight ? <div style={{display:"inline-block",marginLeft:"10px"}}>{props.headerRight}</div> : <></>}
          {props.hideable ? <button className="drop-icon" onClick={()=>{setHidden(!hidden)}}>&#x25BC;</button> : <></>}
          </div>
        </div>
  
        <div style={{maxHeight: props.maxHeight}} className={props.maxHeight ? classes.scrollable: "" }>
          <table className={classes.table}>
            <thead>
              <tr className={classes.tableheading}>
                {!hidden && props.headings ? props.headings.map((heading, index) =>{
                  return <th key={index}>{heading}</th>
                }) : <></>}
              </tr>
            </thead>
            <tbody>
              {!hidden && getData() ? getData().map((row, index) => {
                  return <TableRow
                    key={index}
                    rowData={row}
                  />
              }): <></>}
            </tbody>
          </table>
        </div>
      {props.bottomSection && getData().length? props.bottomSection : <></>}
    </div>
  );
}

export default Table;
