import React, { useState, useEffect } from "react";
import Page from "../../Components/Page";
import Table from "../../Components/Table";
import apiRequest from "../../helpers/connection";
import { useNavigate } from "react-router-dom";
import Popup from "../../Components/Popup";
import { FaEye, FaTrash, FaRegEdit } from "react-icons/fa";
import Dropdown from 'react-dropdown';
import validator from "../../helpers/validator";
import './style.css';
export default function Clients() {
    let navigate = useNavigate();

    let [refresh, setRefresh] = useState(false);
    let [advs, setAdvs] = useState([]);
    let [newAdv, setNewAdv] = useState({});
    let [Types, setTypes] = useState([]);
    let [floors, setFloors] = useState([]);
    let [sections, setSections] = useState([]);
    let [slots, setSlots] = useState([]);
    let [error, setError] = useState("");

    function fetchSections(floorId) {
        setNewAdv((prev) => ({ ...prev, floorId: floorId }));
        setNewAdv((prev) => ({ ...prev, sectionId: null }));
        // fetch floor's sections
        apiRequest(navigate, {
            method: "GET",
            route: "/sections/" + floorId.value,
            onReturn: (data) => {
                let sectionData = data.map((section) => { return { label: section.name, value: section.sectionId } });
                setSections(sectionData);

                // Automatically select the section with the same name as the floor
                const matchingSection = sectionData.find(section => section.label === floorId.label);
                if (matchingSection) {
                    setNewAdv((prev) => ({ ...prev, sectionId: matchingSection }));
                    fetchSlots(matchingSection);
                }
            }
        });
    }

    function fetchSlots(sectionId) {
        setNewAdv((prev) => ({ ...prev, sectionId: sectionId }));
        setNewAdv((prev) => ({ ...prev, slotId: null }));
        // fetch sections's slots
        apiRequest(navigate, {
            method: "GET",
            route: "/slots/" + sectionId.value,
            onReturn: (data) => {
                let slotData = data.map((slot) => { return { label: slot.name, value: slot.slotId } });
                setSlots(slotData);
            }
        });
    }

    useEffect(() => {
        // fetch Advertisment Type 
        apiRequest(navigate, {
            method: "GET",
            route: "/AdvTypes",
            onReturn: (data) => {
                let AdvTypeData = data.map((advTypeD) => { return { label: advTypeD.name, value: advTypeD.advTypeId } });
                setTypes(AdvTypeData);
            }
        });

        // fetch all floors
        apiRequest(navigate, {
            method: "GET",
            route: "/floors",
            onReturn: (data) => {
                let floorData = data.map((floor) => { return { label: floor.name, value: floor.floorId } });
                setFloors(floorData);
            }
        });

        apiRequest(navigate, {
            method: "GET",
            route: "/advertisments",
            onReturn: (data) => {
                let newAdvs = data.map((adv) =>
                    [
                        adv.advertismentId,
                        adv.advertismentName,
                        adv.advType.name,
                        adv.slot.section.floor.name,
                        adv.slot.section.name,
                        adv.slot.name,
                        <div>
                            <EditAdvertisment adv={adv} refresh={() => setRefresh(prev => !prev)} />
                            <button style={{ padding: "10px" }} onClick={() => { navigate("/advertisments/" + adv.advertismentId) }}>
                                <FaEye color="444ce7" />
                            </button>
                            <Popup
                                name={<FaTrash color="e84a50" />}
                                whiteButton={true}
                                actionBtnLabel="Delete"
                                onActionBtnClick={() => {
                                    apiRequest(navigate, {
                                        method: "DELETE",
                                        route: "/advertisments/" + adv.advertismentId,
                                        okStatus: 204,
                                        onReturn: (data) => {
                                            setRefresh((prev) => !prev);
                                        }
                                    });
                                }}
                            >
                                <h2>Are You Sure?</h2>
                                <span>You are going to delete the user {adv.name}</span>
                            </Popup>
                        </div>
                    ]
                );
                setAdvs(newAdvs);
            },
            onError: (response) => {
                navigate("/error");
            }
        });
    }, [refresh]);

    return (
        <Page>
            <Table search={true}
                header="View Advertisments"
                headerRight={
                    <Popup
                        name={<span className="plus-parent"><span className="big-plus">+</span> Create New Adv</span>}
                        actionBtnLabel="Create Advertisment"
                        onClose={() => {
                            setNewAdv({});
                        }}
                        onActionBtnClick={function () {
                            let requiredFields = [
                                { key: "advTypeId", label: "Advertisment Type" },
                                { key: "floorId", label: "Floor" },
                                { key: "sectionId", label: "Section" },
                                { key: "slotId", label: "Slot" },
                                { key: "width", label: "Width" },
                                { key: "height", label: "Height" },
                            ];
                            let valError = validator.validate(requiredFields, newAdv);
                            if (valError != "") {
                                setError(valError);
                                return false;
                            }
                            setError("");

                            apiRequest(navigate, {
                                method: "POST",
                                route: "/advertisments",
                                body: {
                                    ...newAdv,
                                    sectionId: newAdv.sectionId.value,
                                    slotId: newAdv.slotId.value,
                                    floorId: newAdv.floorId.value,
                                    advTypeId: newAdv.advTypeId.value
                                },
                                okStatus: 201,
                                onReturn: (data) => {
                                    setRefresh((prev) => !prev);
                                    setNewAdv({});
                                },
                                onError: () => {
                                    navigate("/error");
                                }
                            });
                        }}
                    >
                        <h1>Create New Advertisment</h1>
                        <span className="error">{error}</span>
                        <label className="block login-label">Advertisment Name</label>
                        <input className={"block shadow login-input "} type="text" value={newAdv.advertismentName ?? ""} onChange={(event) => { setNewAdv((prev) => ({ ...prev, advertismentName: event.target.value })) }} />
                        <label className="block login-label">Advertisment Type</label>
                        <Dropdown options={Types} value={newAdv.advTypeId} onChange={(value) => setNewAdv((prev) => ({ ...prev, advTypeId: value }))} />
                        <label className="block login-label">Floor</label>
                        <Dropdown options={floors} value={newAdv.floorId} onChange={(value) => fetchSections(value)} />
                        <label className="block login-label">Section</label>
                        <Dropdown options={sections} value={newAdv.sectionId} onChange={(value) => fetchSlots(value)} disabled={true}  />
                        <label className="block login-label">Area</label>
                        <Dropdown options={slots} value={newAdv.slotId} onChange={(value) => { setNewAdv((prev) => ({ ...prev, slotId: value })) }} />
                        <label className="block login-label">Width</label>
                        <input className={"block shadow login-input "} type="text" value={newAdv.width ?? ""} onChange={(event) => { setNewAdv((prev) => ({ ...prev, width: event.target.value })) }} />
                        <label className="block login-label">Height</label>
                        <input className={"block shadow login-input "} type="text" value={newAdv.height ?? ""} onChange={(event) => { setNewAdv((prev) => ({ ...prev, height: event.target.value })) }} />
                    </Popup>
                }
                headings={["AdvertismentID", "Advertisment Name", "Advertisment Type", "Floor Name ", "Section Name", "Area Name", ""]}
                data={advs}
            />
        </Page>
    );
}

function EditAdvertisment(props) {
    let navigate = useNavigate();

    let [error, setError] = useState("");
    let [edit, setEdit] = useState({});
    let [Types, setTypes] = useState([]);
    let [floors, setFloors] = useState([]);
    let [sections, setSections] = useState([]);
    let [slots, setSlots] = useState([]);
    let [sectionDisabled, setSectionDisabled] = useState(false);

    function fetchSections(floor) {
        setEdit((prev) => ({ ...prev, floorId: floor }));
        setEdit((prev) => ({ ...prev, sectionId: null }));
        setEdit((prev) => ({ ...prev, slotId: null }));

        apiRequest(navigate, {
            method: "GET",
            route: "/sections/" + floor.value,
            onReturn: (data) => {
                let sectionData = data.map((section) => ({ label: section.name, value: section.sectionId }));
                setSections(sectionData);

                // Automatically select the section with the same name as the floor
                const matchingSection = sectionData.find(section => section.label === floor.label);
                if (matchingSection) {
                    setEdit((prev) => ({ ...prev, sectionId: matchingSection }));
                    setSectionDisabled(true); // Disable the section dropdown
                    fetchSlots(matchingSection);
                } else {
                    setSectionDisabled(false);
                }
            }
        });
    }

    function fetchSlots(sectionId) {
        setEdit((prev) => ({ ...prev, sectionId: sectionId }));
        setEdit((prev) => ({ ...prev, slotId: null }));

        apiRequest(navigate, {
            method: "GET",
            route: "/slots/" + sectionId.value,
            onReturn: (data) => {
                let slotData = data.map((slot) => ({ label: slot.name, value: slot.slotId }));
                setSlots(slotData);
            }
        });
    }

    useEffect(() => {
        apiRequest(navigate, {
            method: "GET",
            route: "/floors",
            onReturn: (data) => {
                let floorData = data.map((floor) => ({ label: floor.name, value: floor.floorId }));
                setFloors(floorData);
            }
        });

        apiRequest(navigate, {
            method: "GET",
            route: "/AdvTypes",
            onReturn: (data) => {
                let AdvTypeData = data.map((advTypeD) => ({ label: advTypeD.name, value: advTypeD.advTypeId }));
                setTypes(AdvTypeData);
            }
        });
    }, []);

    return (
        <Popup
            name={<FaRegEdit />}
            whiteButton={true}
            actionBtnLabel="Edit Area"
            onShow={() => {
                setEdit({
                    ...props.adv,
                    advType: null,
                    slot: null,
                    advTypeId: {
                        value: props.adv.advType.advTypeId,
                        label: props.adv.advType.name
                    },
                    floorId: {
                        value: props.adv.slot.section.floor.floorId,
                        label: props.adv.slot.section.floor.name
                    },
                    sectionId: {
                        value: props.adv.slot.section.sectionId,
                        label: props.adv.slot.section.name
                    },
                    slotId: {
                        value: props.adv.slot.slotId,
                        label: props.adv.slot.name
                    },
                });

                // Fetch sections for the initial floor
                fetchSections({
                    value: props.adv.slot.section.floor.floorId,
                    label: props.adv.slot.section.floor.name
                });
            }}
            onClose={() => {
                setError("");
                setEdit({});
                setSectionDisabled(false);
            }}
            onActionBtnClick={() => {
                let requiredFields = [
                    { key: "advTypeId", label: "Advertisment Type" },
                    { key: "floorId", label: "Floor" },
                    { key: "sectionId", label: "Section" },
                    { key: "slotId", label: "Slot" },
                    { key: "width", label: "Width" },
                    { key: "height", label: "Height" },
                ];
                let valError = validator.validate(requiredFields, edit);
                if (valError != "") {
                    setError(valError);
                    return false;
                }
                setError("");
                apiRequest(navigate, {
                    route: "/advertisments/" + props.adv.advertismentId,
                    method: "PUT",
                    okStatus: 204,
                    body: {
                        ...edit,
                        floorId: edit.floorId.value,
                        sectionId: edit.sectionId.value,
                        slotId: edit.slotId.value,
                        advTypeId: edit.advTypeId.value
                    },
                    onReturn: () => {
                        props.refresh();
                        return true;
                    }
                });
            }}
        >
            <h1>Edit Advertisment</h1>
            <span className="error">{error}</span>
            <label className="block login-label">Advertisment Name</label>
            <input className={"block shadow login-input "} type="text" value={edit.advertismentName ?? ""} onChange={(event) => { setEdit((prev) => ({ ...prev, advertismentName: event.target.value })) }} />
            <label className="block login-label">Advertisment Type</label>
            <Dropdown options={Types} value={edit.advTypeId} onChange={(value) => setEdit((prev) => ({ ...prev, advTypeId: value }))} />
            <label className="block login-label">Floor</label>
            <Dropdown options={floors} value={edit.floorId} onChange={(value) => fetchSections(value)} />
            <label className="block login-label">Section</label>
            <Dropdown value={edit.sectionId} options={sections} onChange={(value) => fetchSlots(value)} disabled={true} />
            <label className="block login-label">Slot</label>
            <Dropdown options={slots} value={edit.slotId} onChange={(value) => { setEdit((prev) => ({ ...prev, slotId: value })) }} />
            <label className="block login-label">Width</label>
            <input className={"block shadow login-input "} type="text" value={edit.width ?? ""} onChange={(event) => { setEdit((prev) => ({ ...prev, width: event.target.value })) }} />
            <label className="block login-label">Height</label>
            <input className={"block shadow login-input "} type="text" value={edit.height ?? ""} onChange={(event) => { setEdit((prev) => ({ ...prev, height: event.target.value })) }} />
        </Popup>
    );
}
