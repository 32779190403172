import LoginPage from "./Pages/Login";
import NotFound from "./Pages/notFound";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import Clients from "./Pages/Clients";
import SingleClient from "./Pages/SingleClient";
import Floors from "./Pages/Floors";
import Sections from "./Pages/Sections";
import Slots from "./Pages/Slots";
import Dashboard from "./Pages/Dashboard";
import Advertisments from "./Pages/Advertisments";
import SingleAdvertisments from "./Pages/SingleAdvertisment";
import ChangePassword from "./Pages/ChangePassword";
import PaymnetDetails from "./Pages/PaymnetDetails";



function App() {
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/home" element={<Dashboard />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/clients/:client_id" element={<SingleClient />} />
          <Route path="Advertisments/:adv_id" element={<SingleAdvertisments />} />
          <Route path="/floors" element={<Floors />} />
          <Route path="/sections" element={<Sections />} />
          <Route path="/slots" element={<Slots />} />
          <Route path="/advertisments" element={<Advertisments />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/PaymentDetails/:clientId/:advertismentId" element={<PaymnetDetails />} />
          <Route path="*" element={<NotFound/>}  />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
