import { useState } from 'react';
import './style.css';
export default function LoginComponent(props){
    const [firstField, setFirstField] = useState("");
    const [secondField, setSecondField] = useState("");

    var classNames = ["login-username-input", "login-username-input"];
    var placeholders = ["Enter your Username", "Ente your Username"];
    for(var i = 0; i < 2; i++){
        if(props.types[i] == "password"){
            classNames[i] = "login-password-input";
            placeholders[i] = "********"
        }
    }
    return (
        <div className='login-page-container'>
        <div className="login-container">
            <img className="login-logo" src="./logo.png" alt="logo" />
            <h2 className="login-heading">{props.heading}</h2>
            <h3 className="login-subheading">{props.subheading}</h3>
            <h3 className="login-subheading error">{props.errorMsg}</h3>
            <div className="container padded-container login-form">
                <label className="block login-label">{props.headings[0]}</label>
                <input className={"block shadow login-input " + classNames[0]}  type={props.types[0]} placeholder={placeholders[0]} onChange={(event) => {setFirstField(event.target.value)}}/>
                <label className="block login-label">{props.headings[1]}</label>
                <input className={"block shadow login-input " + classNames[1]}  type={props.types[1]} placeholder={placeholders[1]} onChange={(event) => {setSecondField(event.target.value)}}/>
                <button className="shadow login-button" onClick={() =>{props.handleSubmit(firstField, secondField)}}>{props.button}</button>
            </div>
        </div>
        </div>
    );
}