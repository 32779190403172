import {useState, useEffect} from "react";
import Page from "../../Components/Page";
import Chart from "react-apexcharts";
import apiRequest from "../../helpers/connection";
import "./style.css";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

export default function Dashboard(){
    let navigate = useNavigate();

    let [sales, setSales] = useState([]);
    let [adData, setAdData] = useState(null);

    useEffect(() => {
        apiRequest(navigate,{
            route: "/statistics",
            method: "GET",
            onReturn: (data) => {
                setSales([
                    {
                        name: "Expected Amounts",
                        data: data.expectedAmounts
                    },
                    {
                        name: "Paid Amounts",
                        data: data.paidAmounts
                    },
                ]);
                setAdData({
                    total: data.totalAds,
                    free: data.freeAds,
                    leased: data.leasedAds
                });
            }
        });
    }, []);

    let barOptions = {
        chart: {
          id: 'year-sales'
        },
        xaxis: {
          categories: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        }
      };

    let donutOptions = {
        chart: {
            type: "donut"
        },
        legend:{
            show: false
        }
    };

    return (
        <Page>
            <h1>Dashboard</h1>
            <div className="chart-row">
            <div div 
            /*style = {{width: '20vw',height: '20vh'}}*/
            >
            <div className="chart-25">
                    <div className="container padded-container">
                        <h2>Your Ads Performance</h2>
                        {adData ? <Chart options={donutOptions} series={[adData.free,adData.leased]} type="donut"/> : <ClipLoader />}
                    </div>
                    
                    <div className="container padded-container--small">
                        <div className="dashboard-label">Total Ads</div>
                        {adData ? <div className="dashboard-value">{adData.total}</div> : <ClipLoader />}
                    </div>

                    <div className="container padded-container--small">
                        <div className="dashboard-label">Free Ads</div>
                        {adData ? <div className="dashboard-value">{adData.free}</div> : <ClipLoader />}
                    </div>
                    <div className="container padded-container--small">
                        <div className="dashboard-label">Occupied Ads</div>
                        {adData ? <div className="dashboard-value">{adData.leased}</div> : <ClipLoader />}
                    </div>
                </div>

            </div>
                
               <div style={{width:'60vw'}}>
                        <div className = "container padded-container chart-50">
                          
                            <h2> This Year 's Sales</h2> {
                                sales.length != 0 ? <Chart options = {
                                    barOptions
                                }
                                series = {
                                    sales
                                }
                                type = "bar" />: <ClipLoader />
                            } </div>
               </div>
                
            </div>
        </Page>
    );
}