import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import apiRequest from "../../helpers/connection";
import helpers from "../../helpers/users";
import ChangePasswordComponent from "../../Components/ChangePassword";

const ChangePasswordPage = (props) => {
    let navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState("");
    const  username = helpers.getUserName();
    useEffect(() => {
    }, []);

    return (
        <ChangePasswordComponent
            heading="Change your password"
            subheading="Please enter your old and new passwords"
            errorMsg={errorMsg}
            button="Change Password"
            handleSubmit={(oldPassword, newPassword, confirmPassword) => {
                if (newPassword !== confirmPassword) {
                    setErrorMsg("New passwords do not match");
                    return;
                }
                toast.loading("Changing password...", { duration: 1000 });
                apiRequest(navigate, {
                    method: "POST",
                    route: "/Auth/ChangePassword",
                    body: {
                        userName:username,
                        oldPassword: oldPassword,
                        newPassword: newPassword,
                        confirmPassword: newPassword,
                    },
                    okStatus: 200,
                    onReturn: () => {
                        toast.success("Password changed successfully");
                        navigate("/home");
                    },
                    onError: (response) => {
                        if (response.status === 400) {
                            setErrorMsg("Incorrect old password");
                        } else {
                            setErrorMsg("An error occurred");
                        }
                    }
                });
            }}
        />
    );
};

export default ChangePasswordPage;
