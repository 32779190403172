import React, {useState, useEffect} from "react";
import Page from "../../Components/Page";
import Table from "../../Components/Table";
import apiRequest from "../../helpers/connection";
import { useNavigate } from "react-router-dom";
import Popup from "../../Components/Popup";
import { FaEye, FaTrash, FaRegEdit } from "react-icons/fa";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import validator from "../../helpers/validator";

export default function Clients(){
    let navigate = useNavigate();

    let [refresh, setRefresh] = useState(false);
    let [clients, setClients] = useState([]);
    let [error, setError] = useState("");
    let [floors, setFloors] = useState([]);
    let [newClient, setNewClient] = useState({});

    useEffect(() => {
        // fetch all floors
        apiRequest(navigate,{
            method: "GET",
            route: "/floors",
            onReturn: (data) => {
                let floorData = data.map((floor) => {return {label: floor.name, value: floor.floorId}});
                setFloors(floorData);
            }
        });

        // fetch all Sections
        apiRequest(navigate,{
            method: "GET",
            route: "/Sections",
            onReturn: (data)=>{
                let newSections = data.map((Section) => 
                    [
                      Section.sectionId,
                      Section.name,
                      Section.floor.name,
                        <div>
                            <EditSection section={Section} refresh={() => setRefresh(prev => !prev)} />
                            <Popup 
                                name={<FaTrash color="e84a50" />} 
                                whiteButton={true}
                                actionBtnLabel="Delete"
                                onActionBtnClick={() => {
                                    apiRequest(navigate,{
                                        method: "DELETE",
                                        route: "/sections/" + Section.sectionId,
                                        okStatus: 204,
                                        onReturn: (data) => {
                                            setRefresh((prev) => !prev);
                                        }
                                    });
                                }}
                            >
                                <h2>Are You Sure?</h2>
                                <span>You are going to delete the section {Section.name}</span>
                            </Popup>

                        </div>
                    ]
                );
                setClients(newSections);
            },
            onError: (response)=>{
                navigate("/error");
            }
        });
    } , [refresh]);
    
    return(
    <Page>
        <Table 
            header="View Sections"
            headerRight = {
                <Popup 
                    name={<span className="plus-parent"><span className="big-plus">+</span> Add New Section</span>}
                    actionBtnLabel = "Create Section"
                    onClose={
                        () => {
                            setError("");
                            setNewClient({});
                        }
                    }
                    onActionBtnClick = {function (){
                        let requiredFields = [
                            {key: "name", label: "Name"},
                            {key: "floorId", label: "Floor"}
                        ];
                        let valError = validator.validate(requiredFields, newClient);
                        if(valError != ""){
                            setError(valError);
                            return false;
                        }
                        setError("");
                        apiRequest(navigate,{
                            method:"POST",
                            route: "/sections",
                            body:{
                                ...newClient,
                                floorId: newClient.floorId.value
                            },
                            okStatus: 201,
                            onReturn:(data)=>{
                                setRefresh( (prev) => !prev);
                                setNewClient({});
                            },
                            onError:()=>{
                                navigate("/error");
                            }
                        });
                        return true;
                    }}
                >
                    <h1>Create New Section</h1>
                    <span className="error">{error}</span>
                    <label className="block login-label">Floor</label>
                    <Dropdown options={floors} value={newClient.floorId} onChange={(value) => setNewClient((prev) => ({...prev, floorId: value}))}/>
                    <label className="block login-label">Section Name</label>
                    <input className={"block shadow login-input "}  type="text" value={newClient.name ?? ""} onChange={(event) => {setNewClient((prev) => ({...prev, name:event.target.value}))}}/>
                </Popup>
            }
            headings = {["Section ID","Section Name","Floor Name" , ""]}
            data = {clients}
        
        />
    </Page>
    );
}

function EditSection(props){
    let navigate = useNavigate();

    let [error, setError] = useState("");
    let [edit, setEdit] = useState({sectionId: props.section.sectionId});
    let [floors, setFloors] = useState([]);

    useEffect( () => {
        // fetch all floors
        apiRequest(navigate,{
            method: "GET",
            route: "/floors",
            onReturn: (data) => {
                let floorData = data.map((floor) => {return {label: floor.name, value: floor.floorId}});
                setFloors(floorData);
            }
        });
    }, []);

    return (
         <Popup 
             name={<FaRegEdit />}
             whiteButton={true}
             actionBtnLabel = "Edit Section"
             onShow={
                 () =>{
                     setEdit(prev => ({
                        ...prev,
                         name: props.section.name,
                         floorId: {
                            value: props.section.floor.floorId,
                            label: props.section.floor.name
                        }
                     }));
                 }
             }
             onClose={
                 () => {
                     setError("");
                     setEdit({sectionId: props.section.sectionId});
                 }
             }
             onActionBtnClick = {function (){
                let requiredFields = [
                    {key: "name", label: "Name"},
                    {key: "floorId", label: "Floor"}
                ];
                let valError = validator.validate(requiredFields, edit);
                if(valError != ""){
                    setError(valError);
                    return false;
                }
                setError("");
                apiRequest(navigate, {
                    route: "/sections/" + props.section.sectionId,
                    method: "PUT",
                    okStatus: 204,
                    body: {
                        ...edit,
                        floorId: edit.floorId.value
                    },
                    onReturn: () => {
                        props.refresh();
                        return true;
                    }
                });
             }}
         >
            <h1>Edit New Section</h1>
            <span className="error">{error}</span>
            <label className="block login-label">Floor</label>
            <Dropdown options={floors} value={edit.floorId} onChange={(value) => setEdit((prev) => ({...prev, floorId: value}))}/>
            <label className="block login-label">Section Name</label>
            <input className={"block shadow login-input "}  type="text" value={edit.name ?? ""} onChange={(event) => {setEdit((prev) => ({...prev, name:event.target.value}))}}/>
         </Popup>

    );
}